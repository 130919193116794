import React from "react";

export const Fotter = () => {
  return (
    <div>
      <footer className="bg-dark text-light p-5 text-center mt-5">
        Copyright &copy; All right Reserved. Made by me
      </footer>
    </div>
  );
};
